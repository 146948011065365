<template>
  <b-card class="roles h-100 shadow-sm" no-body>
    <b-card-header>
      <b-form>
        <b-form-row>
          <b-col v-if="userName">
            <h5>
              <strong>{{ userName }}</strong>
            </h5>
          </b-col>
          <b-col>
            <b-button-toolbar>
              <b-button-group class="ml-auto">
                <back-button />
                <b-button
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('views.roles.AddRole')"
                  @click="AddRole"
                >
                  <b-icon-plus />
                </b-button>
                <b-button
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('views.roles.RemoveRole')"
                  @click="ReoveRole"
                >
                  <b-icon-trash />
                </b-button>
              </b-button-group>
            </b-button-toolbar>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group :label="$t('views.roles.OU')" label-for="input-1">
              <b-form-select
                v-model="selectedOU"
                v-on:change="onChangeSelectedOU"
                :options="OrganizationalUnits"
                value-field="idOrganizationalUnit"
                text-field="nameOrganizationalUnit"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >-- Please select an option --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('views.roles.Roles')" label-for="input-1">
              <b-form-select
                v-model="selectedAvailableRole"
                :options="AvailableRoles"
                value-field="idRole"
                text-field="nameRole"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>
    </b-card-header>
    <b-table
      ref="roles"
      :fields="fields"
      :items="roles"
      small
      outlined
      selectable
      select-mode="single"
      @row-selected="onRowSelected"
    >
      <template v-slot:table-busy>
        <simple-loader />
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    userName: {
      type: String,
      default: "",
    },
    idCustomer: Number,
  },
  data() {
    return {
      OptionCustomers: [],
      selectedCustomer: "",
      OptionOU: [],
      selectedOU: null,
      OptionAvailableRoles: [],
      selectedAvailableRole: "",
      RoleSelected: [],
    };
  },
  computed: {
    ...mapGetters("OrganizationalUnit", ["UnitOrganitaion"]),
    ...mapGetters("User", [
      "roles",
      "Customers",
      "OrganizationalUnits",
      "AvailableRoles",
    ]),
    fields() {
      return [
        {
          key: "idOrganizationalunit",
          sortable: true,
          label: this.$t("views.roles.fields.IdOrganizationalunit"),
        },
        {
          key: "nameOrganizationalUnit",
          sortable: true,
          label: this.$t("views.roles.fields.OrganizationalUnitName"),
        },
        {
          key: "roleName",
          sortable: true,
          label: this.$t("views.roles.fields.RoleName"),
        },
      ];
    },
  },
  watch: {
    idCustomer(newValue) {
      this.fetchOrganizationalUnits(newValue);
    },
    userName() {
      const that = this;
      this.getRoles({
        api: this.$api,
        userName: this.userName,
        idOURole: this.UnitOrganitaion,
      })
        .then(() => {
          that.$refs.roles.refresh();
        })
        .catch((error) =>
          this.$bvToast.toast(error, {
            variant: "danger",
            title: this.$t("views.roles.LoadingRoles"),
            autoHideDelay: 5000,
            appendToast: true,
          })
        );
    },
  },
  mounted() {
    this.fetchOrganizationalUnits();
    if (!this.userName) return;
    const that = this;
    this.getRoles({
      api: this.$api,
      userName: this.userName,
      idOURol: this.UnitOrganitaion,
    })
      .then(() => {
        that.$refs.roles.refresh();
      })
      .catch((error) => {
        that.$refs.roles?.refresh();
        this.$bvToast.toast(error, {
          variant: "danger",
          title: this.$t("views.roles.LoadingRoles"),
          autoHideDelay: 5000,
          appendToast: true,
        });
      });
  },
  methods: {
    ...mapActions("User", [
      "getUser",
      "getRoles",
      "getCustomers",
      "getOrganizationalUnits",
      "getAvailableRoles",
      "addRole",
      "removeRole",
    ]),
    onChangeSelectedCustomer() {
      this.fetchOrganizationalUnits();
    },
    onChangeSelectedOU() {
      this.fetchAvailableRoles();
    },
    fetchAvailableRoles() {
      const that = this;
      this.getAvailableRoles({
        api: that.$api,
        idOU: that.selectedOU,
      })
        .then((AvailableRoles) => {
          that.OptionAvailableRoles = AvailableRoles.data;
          that.selectedAvailableRole = that.AvailableRoles[0].idRole;
        })
        .catch((error) =>
          this.$bvToast.toast(error, {
            variant: "danger",
            title: this.$t("views.roles.LoadingRoles"),
            autoHideDelay: 5000,
            appendToast: true,
          })
        );
    },
    fetchOrganizationalUnits(newValue) {
      const that = this;
      this.getOrganizationalUnits({
        api: that.$api,
        idCustomer: that.idCustomer || newValue,
        idOURole: that.UnitOrganitaion,
      })
        .then((OU) => {
          that.selectedOU =
            (OU.find((x) => x.default) || {}).idOrganizationalUnit || null;
          that.fetchAvailableRoles();
        })
        .catch((error) => {
          this.$bvToast.toast(error, {
            variant: "danger",
            title: this.$t("views.roles.LoadingOrganizationalUnits"),
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },
    onRowSelected(items) {
      this.RoleSelected = items;
    },
    AddRole() {
      this.addRole({
        api: this.$api,
        userName: this.userName,
        idOU: this.selectedOU,
        nameOrganizationalUnit: this.OrganizationalUnits.find(
          (x) => x.idOrganizationalUnit === this.selectedOU
        ).nameOrganizationalUnit,
        idOURol: this.UnitOrganitaion,
        idRole: this.selectedAvailableRole,
        nameRole: this.AvailableRoles.find(
          (x) => x.idRole === this.selectedAvailableRole
        ).nameRole,
      })
        .then((message) => {
          this.$bvToast.toast(message, {
            variant: "success",
            title: this.$t("views.roles.AddRole"),
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((error) => {
          this.$bvToast.toast(error, {
            variant: "danger",
            title: this.$t("views.roles.AddRole"),
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },
    ReoveRole() {
      const role = this.RoleSelected[0];
      this.removeRole({
        api: this.$api,
        userName: this.userName,
        idOU: role.idOrganizationalunit,
        idOURole: this.UnitOrganitaion,
        nameRole: role.roleName,
      })
        .then((message) => {
          this.$bvToast.toast(message, {
            variant: "success",
            title: this.$t("views.roles.RemoveRole"),
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((error) => {
          this.$bvToast.toast(error, {
            variant: "danger",
            title: this.$t("views.roles.RemoveRole"),
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },
  },
};
</script>

<style></style>
