<template>
  <span>
    <Alert />
    <Roles
      v-if="user"
      v-bind:userName="user.userName"
      :idCustomer="user.idCustomer"
    />
  </span>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Roles from "@/components/Roles";
import Alert from "@/components/general/alert";

export default {
  watch: {},
  beforeRouteLeave(to, from, next) {
    if (this.roles.length) {
      next();
      return;
    }
    next(false);
    this.$bvModal
      .msgBoxOk(this.$t("views.roles.atLeastOneRole"), {
        title: this.$t("views.roles.Warning"),
        size: "sm",
        buttonSize: "sm",
        okVariant: "warning",
        headerClass: "p-2 bg-warning border-bottom-0",
        footerClass: "p-2 border-top-0",
        centered: true,
      })
      .then((value) => {
        this.boxOne = value;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  components: {
    Roles,
    Alert,
  },
  created() {
    this.loadUser();
  },
  computed: {
    ...mapGetters("OrganizationalUnit", ["UnitOrganitaion"]),
    ...mapGetters("User", ["user", "roles"]),
  },
  methods: {
    ...mapActions("User", ["getUser"]),
    loadUser() {
      if (!this.$route.params.user) return;
      this.getUser({
        api: this.$api,
        userName: this.$route.params.user,
      })
        .then(() => {
          this.$bvToast.toast(this.$t("views.roles.UserDataLoaded"), {
            variant: "success",
            title: this.$t("views.roles.UserLoading"),
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((error) => {
          this.$bvToast.toast(error, {
            variant: "danger",
            title: this.$t("views.roles.UserLoading"),
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },
  },
};
</script>

<style></style>
